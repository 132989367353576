@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    @apply bg-white text-grey-700;
  }

  body {
    @apply pt-[48px] leading-[150%] antialiased md:pt-[56px];
  }

  strong {
    @apply font-semibold;
  }
}

@layer components {
  /* ------------------------------------------------------------------------ */
  /* Typography ------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Headings --------------------------------------------------------------- */
  .display {
    @apply font-poppins text-2xl font-semibold leading-9 tracking-tight md:text-3xl md:font-medium md:leading-10 xl:text-5xl xl:font-normal xl:leading-[3.6rem];
  }

  .h1 {
    @apply font-poppins text-6xl;
  }

  .h2 {
    @apply font-poppins text-5xl;
  }

  .h3 {
    @apply font-poppins text-4xl font-medium;
  }

  .h4 {
    @apply font-poppins text-2xl font-semibold;
  }

  .h5 {
    @apply font-poppins text-xl font-medium md:text-2xl xl:text-3xl xl:font-normal;
  }

  .h6 {
    @apply font-poppins font-medium md:text-lg xl:text-xl;
  }

  /* Subtitles -------------------------------------------------------------- */
  .subtitle {
    @apply text-balance font-poppins;
  }

  .subtitle-1 {
    @apply text-lg;
    line-height: 1.6875rem;
  }

  .subtitle-2 {
    @apply text-lg font-semibold;
    line-height: 1.6875rem;
  }

  .subtitle-3 {
    @apply font-semibold leading-6;
  }

  /* ------------------------------------------------------------------------ */
  /* Forms ------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ */

  /* Fields ----------------------------------------------------------------- */
  .form-group.has-icon input.form-control,
  .form-group.has-icon textarea.form-control {
    @apply pl-10;
  }

  .form-group.has-icon select.form-control {
    @apply pl-16;
  }

  .form-group > div > svg {
    @apply absolute top-1/2 hidden shrink-0 translate-y-[-50%];
  }

  .form-group > div > svg.disabled {
    @apply text-grey-400;
  }

  .form-group.has-error > div > svg,
  .form-group.has-icon > div > svg {
    @apply z-10 block;
  }

  .form-group.has-error > div > svg {
    @apply right-4 text-xs text-error-400;
    line-height: 2.65rem;
  }

  .form-group.has-error > div > select + svg {
    @apply right-10;
  }

  .form-group.has-icon > div > svg {
    @apply left-4;
  }

  .form-label {
    @apply inline-flex px-4 text-sm font-semibold leading-7;
    letter-spacing: 0.0175rem;
  }

  .form-label span {
    @apply text-secondary-300;
  }

  .form-control {
    @apply block w-full rounded-lg border border-primary-200 bg-white px-4 py-2 text-grey-600 transition-colors placeholder:text-grey-600;
    @apply hover:bg-primary-50;
    @apply focus:ring-secondary-300 focus-visible:border focus-visible:border-secondary-300 focus-visible:bg-primary-50;
    @apply disabled:border-grey-200 disabled:bg-grey-100 disabled:text-grey-400 disabled:placeholder:text-grey-400;
  }

  .form-group.has-error .form-control {
    @apply border-error-400 bg-white pr-10 focus:ring-error-400;
  }

  .form-hint {
    @apply block px-2 text-xs font-semibold leading-6 text-grey-500;
  }

  .checkbox {
    @apply h-[20px] w-[20px] rounded-sm text-primary-300 focus:outline-primary-300;
  }

  .radio {
    @apply h-[20px] w-[20px] bg-none text-primary-300 focus:outline-primary-300;
  }

  /* Buttons ---------------------------------------------------------------- */
  .btn {
    @apply inline-flex shrink-0 items-center justify-center rounded-lg px-4 py-2 text-center font-poppins font-semibold transition-colors;
    @apply focus-visible:outline-2;
  }

  .btn-rounded {
    @apply elevation-1 flex h-[5rem] w-[5rem] shrink-0 items-center justify-center rounded-full border transition-colors md:h-[4.75rem] md:w-[4.75rem] xl:h-[7rem] xl:w-[7rem];
    @apply border-primary-100 bg-white;
    @apply hover:border-primary-200 hover:bg-primary-50;
    @apply active:border-primary-300 active:bg-primary-200;
    @apply focus-visible:bg-primary-50 focus-visible:outline-secondary-300;
    @apply disabled:border-grey-200 disabled:bg-grey-100;
  }

  .btn-rounded img {
    @apply max-h-[48px] max-w-[48px] xl:max-h-[72px] xl:max-w-[72px];
  }

  .btn-rounded:disabled img {
    @apply opacity-40;
  }

  .btn-sm {
    @apply gap-2 text-sm leading-4;
  }

  .btn-md {
    @apply h-10 gap-2 text-sm leading-4;
  }

  .btn-md svg {
    font-size: 1.25rem;
  }

  .btn-lg {
    @apply h-12 gap-2 leading-4;
  }

  .btn-lg svg {
    font-size: 1.5rem;
  }

  .btn-primary {
    @apply bg-secondary-300 uppercase text-primary-500;
    @apply xl:hover:bg-secondary-200;
    @apply active:bg-secondary-400;
    @apply focus-visible:bg-secondary-200 focus-visible:outline-primary-300;
    @apply disabled:bg-grey-200 disabled:text-grey-400;
  }

  .btn-secondary {
    @apply bg-primary-300 uppercase text-grey-50;
    @apply xl:hover:bg-primary-400;
    @apply active:bg-primary-500;
    @apply focus-visible:bg-primary-400 focus-visible:outline-secondary-300;
    @apply disabled:bg-grey-200 disabled:text-grey-400;
  }

  .btn-form-control {
    @apply flex w-full items-center justify-between border border-primary-200 bg-white text-base font-normal text-grey-800;
    @apply xl:hover:bg-primary-50;
    @apply active:bg-primary-100;
    @apply focus-visible:bg-primary-50 focus-visible:outline-secondary-300;
    @apply opacity-70 disabled:border-grey-200 disabled:bg-grey-100 disabled:text-grey-400;
  }

  .btn-form-control svg {
    @apply text-sm;
    margin-right: -3px;
  }

  .btn-form-control:disabled svg {
    @apply text-grey-600;
  }

  .btn-outline {
    @apply border border-primary-400 text-primary-400;
    @apply hover:text-primary-300 xl:hover:border-primary-300;
    @apply active:border-primary-500 active:text-primary-500;
    @apply focus-visible:bg-primary-50 focus-visible:outline-secondary-300;
    @apply disabled:border-grey-500 disabled:text-grey-500;
  }

  .btn-ghost {
    @apply text-primary-400;
    @apply xl:hover:text-primary-300;
    @apply active:text-primary-500;
    @apply focus-visible:text-primary-400 focus-visible:outline-secondary-300;
    @apply disabled:text-grey-500;
  }

  .btn-ghost-red {
    @apply text-error-400;
    @apply xl:hover:text-error-300;
    @apply active:text-error-500;
    @apply focus-visible:text-error-400 focus-visible:outline-secondary-300;
    @apply disabled:text-grey-300;
  }

  .btn-ghost-white {
    @apply text-grey-50;
    @apply xl:hover:text-primary-100;
    @apply active:text-primary-200;
    @apply focus-visible:text-primary-100 focus-visible:outline-secondary-300;
    @apply disabled:text-grey-300;
  }

  /* ------------------------------------------------------------------------ */
  /* Backdrop --------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .backdrop {
    @apply fixed left-0 flex w-full flex-col items-center bg-primary-500/15;
  }

  .backdrop-close {
    @apply fixed left-0 top-0 h-full w-full cursor-default;
  }

  /* ------------------------------------------------------------------------ */
  /* Bottom Sheet ----------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .bottom-sheet-backdrop {
    @apply backdrop top-0 z-[60] h-full opacity-0 transition-opacity;
  }

  .bottom-sheet-backdrop-close {
    @apply backdrop-close;
  }

  .bottom-sheet {
    @apply fixed -bottom-full left-0 flex w-full flex-col rounded-t-lg bg-white transition-all;
  }

  .bottom-sheet.isMaximized {
    @apply h-[80%];
  }

  .bottom-sheet.isShown {
    @apply bottom-0;
  }

  .bottom-sheet-tongue {
    @apply flex items-center justify-center py-4;
  }

  .bottom-sheet-tongue div {
    @apply h-[3px] w-[40px] self-center rounded-full bg-grey-200;
  }

  .bottom-sheet-header {
    @apply relative flex items-center justify-between px-4;
  }

  .bottom-sheet-title {
    @apply font-poppins text-lg font-semibold;
  }

  .bottom-sheet-header .close {
    @apply absolute right-0 top-1/2 mt-[-22px];
  }

  .bottom-sheet-body {
    @apply flex h-[320px] flex-col space-y-4 p-4;
  }

  /* ------------------------------------------------------------------------ */
  /* Dialogs ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .dialog-backdrop {
    @apply backdrop top-0 z-[60] h-full justify-center xl:px-4 xl:py-6;
  }

  .dialog-backdrop-close {
    @apply backdrop-close;
  }

  .dialog {
    @apply xl:elevation-3 relative flex w-full flex-col overflow-hidden bg-white max-xl:h-full xl:max-w-[40rem] xl:rounded-2xl;
  }

  .dialog-header {
    @apply flex h-[3.6rem] shrink-0 items-center justify-between space-x-4 border-b border-grey-200 pl-4 pr-2 md:pl-6 xl:rounded-t-2xl;
  }

  .dialog-header-title {
    @apply font-poppins font-medium;
  }

  .dialog-body {
    @apply flex grow flex-col justify-between space-y-4 overflow-y-scroll rounded-b-2xl p-4 max-xl:max-h-full md:space-y-6 md:p-6 xl:max-h-[24rem];
  }

  .dialog-body-content {
    @apply flex flex-col space-y-6 pb-8 text-grey-600 md:pb-12;
  }

  .dialog-body-title {
    @apply font-poppins text-2xl font-medium text-grey-800;
  }

  .dialog-body-subtitle {
    @apply font-poppins font-medium;
  }

  .dialog-footer {
    @apply flex items-center justify-between  p-4 max-xl:flex-col max-xl:space-y-4 xl:space-x-4 xl:rounded-b-2xl;
  }

  .dialog-footer .btn {
    @apply grow max-xl:w-full;
  }

  /* ------------------------------------------------------------------------ */
  /* Modals ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .modal-backdrop {
    @apply backdrop top-[50px] z-40 h-[calc(100%-50px)] justify-center md:top-[56px] md:h-[calc(100%-56px)] xl:top-0 xl:z-[60] xl:h-full;
  }

  .modal-backdrop-close {
    @apply backdrop-close;
  }

  .modal {
    @apply xl:elevation-3 relative flex w-full flex-col overflow-hidden bg-white max-xl:h-full xl:h-[40rem] xl:max-w-[40rem] xl:rounded-2xl;
  }

  .modal-header {
    @apply flex h-[3.6rem] shrink-0 items-center justify-between space-x-4 border-b border-grey-200 pl-6 pr-2 max-xl:hidden xl:rounded-t-2xl;
  }

  .modal-header-title {
    @apply font-poppins font-medium;
  }

  .modal-body {
    @apply flex h-full grow flex-col overflow-y-scroll text-grey-600 xl:space-y-4 xl:rounded-b-2xl xl:p-6;
  }

  .modal-body-title {
    @apply font-poppins text-2xl font-medium text-grey-800;
  }

  /* ------------------------------------------------------------------------ */
  /* Elevations ------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .elevation-1 {
    box-shadow: 0px 2px 0px 0px rgba(36, 41, 51, 0.04);
  }

  .elevation-2 {
    box-shadow: 0px -2px 0px 0px rgba(36, 41, 51, 0.04);
  }

  .elevation-3 {
    box-shadow: 0px 4px 16px 0px rgba(36, 41, 51, 0.08);
  }

  .elevation-4 {
    box-shadow: 0px 4px 16px 0px rgba(36, 41, 51, 0.16);
  }

  /* ------------------------------------------------------------------------ */
  /* List Item -------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .list-item {
    @apply w-full p-4 text-left transition-colors;
    @apply hover:bg-primary-50;
    @apply active:bg-primary-200;
    @apply focus:outline-none focus-visible:relative focus-visible:z-10 focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-secondary-300;
  }

  .list-item.has-icon {
    @apply flex items-center space-x-2;
  }

  /* ------------------------------------------------------------------------ */
  /* Others ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */
  .close {
    @apply h-[44px] w-[44px];
  }
}

/* -------------------------------------------------------------------------- */
/* Google Maps Autocomplete ------------------------------------------------- */
/* -------------------------------------------------------------------------- */
.pac-container {
  @apply border-primary-200 bg-white;
  @apply max-h-[320px] overflow-y-auto border p-2 shadow-lg;
}

.pac-item {
  @apply border-grey-200 text-grey-800;
  @apply flex cursor-pointer items-center truncate px-2 py-2 font-poppins text-base;
}

.pac-item:first-child {
  @apply border-t-0;
}

.pac-item:last-child {
  @apply mb-2;
}

.pac-icon {
  @apply hidden;
}

.pac-item-query {
  @apply text-grey-800;
  @apply truncate text-base;
}

.pac-matched {
  @apply text-primary-400;
  @apply font-semibold;
}

.pac-item:hover {
  @apply bg-primary-100;
}

/* -------------------------------------------------------------------------- */
/* Securiti ----------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
@media screen and (min-width: 1280px) {
  .cc-grower {
    position: fixed;
    bottom: 8px;
    left: 8px;
    z-index: 3;
    width: 100%;
  }
}

.cc-window {
  color: #fff !important;
  background-color: #242933 !important;
  align-items: center;
  flex-flow: row nowrap !important;
}

@media screen and (max-width: 1279px) {
  .cc-window {
    position: initial;
    font-size: 11px;
  }
}

@media screen and (min-width: 1280px) {
  .cc-window {
    border-radius: 4px;
  }
}

.cc-window.cc-banner {
  padding: 8px;
}

@media screen and (min-width: 1280px) {
  .cc-window.cc-banner {
    width: calc(100% - 16px);
    padding: 12px 12px 12px 16px;
  }
}

@media (max-width: 767px) {
  .cc-window.cc-banner .cc-compliance {
    flex: 1 0 auto;
  }
}

@media (max-width: 767px) {
  .cc-window .cc-message {
    margin-bottom: 0;
  }
}

.cc-window .cc-message p span {
  color: #fff;
}

.cc-message-container {
  padding-right: 8px;
}

.cc-banner.cc-bottom {
  bottom: 8px;
  left: 8px;
}

.cc-banner .cc-message {
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.cc-banner .cc-compliance {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
}

.cc-banner .cc-compliance a {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.cc-link {
  padding: 0;
  color: #fff !important;
}

.cmp-pref-link {
  order: 1;
}

.cc-btn {
  height: 42px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}

.cc-deny {
  display: none;
}

.cc-dismiss {
  margin-left: 8px !important;
  order: 2;
}

.cc-theme-block .cc-btn {
  border-radius: 2px;
}

.cc-btn {
  color: #163150 !important;
  background-color: #e58c06 !important;
}
